import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import config from './config';

import './index.css';

import registerServiceWorker from './serviceWorker';

const defaultApolloOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
}

const link = createHttpLink({
  uri: config.serverUrl + '/graphql',
  credentials: 'include'
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('auth-token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(link),
  defaultOptions: defaultApolloOptions,
  request: operation => {
    operation.setContext(context => ({
      headers: {
        ...context.headers
      }
    }));
  }
});

const container = document.getElementById('root');

const root = createRoot(container);

root.render(
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
);

registerServiceWorker();
