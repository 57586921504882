import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { ErrorBoundary, Site, Dashboard, Login } from './components';
import GlobalFonts from './fonts/fonts';
import { useRoutes, navigate } from 'raviger'
import Routes from './routes';
import './App.css';

//const Login = React.lazy(() => import('./components'));

// Styles

const theme = {
  backgroundColor: '#FEF9EF',
  primaryColor: '#02C39A',
  altBackgroundColor: '#055574',
  accentColor: '#05668D',
  brightAccentColor: '#00A896',
  altAccentColor: '#BBBBBF',
  darkAccentColor: '#028090',
  vignetteColor: '#222C37',
  textColor: 'black',
  altTextColor: '#8298A3' 
}

const AppWrapper = styled.div`
  position: relative;
  background-color: ${theme.backgroundColor};
  height: 100vh;
`;

// JSX
function App(props){

  const routeResult = useRoutes(Routes);


  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <AppWrapper key='app'>
          <GlobalFonts />
          {routeResult}
        </AppWrapper>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;
